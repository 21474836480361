<template>
	<div class="phone">
		<div class="phone__case"></div>
		<div class="phone__speaker"></div>
		<div class="phone__button1"></div>
		<div class="phone__button2"></div>
		<div class="phone__screen">
			<Images width="100%" height="100%" align="top" v-if="image" :image="image" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		image: String,
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.phone{
		position: relative;
		padding-bottom: 100%;
		width: 80%;
		margin: auto;
	@include breakpoint(sm){
		padding-bottom: 75%;
		width: 100%;
	}
}
.phone__case{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		left: 33%;
		top: 5%;
		width: 34%;
		height: 90%;
		overflow: hidden;
		border-radius: 20px;
	}
}
.phone__screen{
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border: 2px solid #9F6F73;
	
	@include breakpoint(sm){
		left: 35%;
		top: 13%;
		width: 30%;
		height: 75%;
	}
}
.phone__speaker{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		left: 47%;
		top: 8%;
		width: 6%;
		height: 1%;
		overflow: hidden;
		border-radius: 20px;
	}
}
.phone__button1{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		border-right:none;
		left: 32.5%;
		top: 25%;
		width: .5%;
		height: 6%;
		overflow: hidden;
		border-radius: 4px 0 0 4px;
	}
}
.phone__button2{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		border-right:none;
		left: 32.5%;
		top: 32%;
		width: .5%;
		height: 6%;
		overflow: hidden;
		border-radius: 4px 0 0 4px;
	}
}
</style>

<!-- Used in : AR -->